<template lang="pug">
  div.homepage
    div.custom__loader
    HomeBanner
    ServiceCard
    CasesBlock
    AboutBlock
    PartnershipBlock
    section.section
      div.container
        FeedbackForm
</template>

<script>
export default {
  name: 'HomePage',
  components: {
    HomeBanner: () => import('@/components/HomeBanner'),
    ServiceCard: () => import('@/components/ServiceCard'),
    CasesBlock: () => import('@/components/CasesBlock'),
    AboutBlock: () => import('@/components/AboutBlock'),
    PartnershipBlock: () => import('@/components/PartnershipBlock'),
    FeedbackForm: () => import('@/components/FeedbackForm')
  }
}
</script>
